<template>
  <div class="chat-container">
    <div class="messages" ref="messages">
      <div>
        <div class="message system">
          <p>Hi! Welcome to the BookedSolid interactive demo. Type a message to get started.</p>
        </div>
      </div>
      <div class="message" v-for="message in messages" :key="message._id"
        :class="message.to == 'system' ? 'user' : 'system'">
        <p>
          <div v-html="message.message.replace(/\n/g, '<br/>')"></div>
          <span>{{ new Date(message.date).toString().substring(15, 21) }}, {{ new
            Date(message.date).toISOString().substring(0, 10) }}</span>
        </p>
      </div>
    </div>
    <div class="message-bar">
      <input type="text" v-model="newMessage" placeholder="Type a message..." />
      <button @click="sendMessage"><i class="fa-solid fa-paper-plane"></i></button>
    </div>
  </div>
</template>

<script>
import {
  sendDemoMessage,
  getDemoConversation
} from "@/app/routes";

export default {
  data() {
    return {
      messages: [],
      newMessage: ""
    };
  },
  async mounted() {
    this.messages = await getDemoConversation();
    this.$nextTick(() => {
      const messages = this.$refs.messages;
      messages.scrollTop = messages.scrollHeight;
    });
    document.querySelector('input').addEventListener('keypress', async (e) => {
      if (e.key === 'Enter') {
        await this.sendMessage();
        setTimeout(() => {
          this.newMessage = '';
        }, 1000);
      }
    });
  },
  methods: {
    async sendMessage() {
      if (!this.newMessage) return;
      const message = this.newMessage;
      this.messages.push({
        message,
        to: "system",
        date: new Date()
      });
      this.newMessage = "";
      this.$nextTick(() => {
        const messages = this.$refs.messages;
        messages.scrollTop = messages.scrollHeight;
      });
      const response = await sendDemoMessage(message);
      if (!localStorage.demoId) localStorage.demoId = response.id;
      if (this.activityTimeout) clearTimeout(this.activityTimeout);
      this.activityTimeout = setTimeout(async () => {
        const endTime = Date.now() + 20000;
        const fetchMessages = async () => {
          if (Date.now() < endTime) {
            const messages = await getDemoConversation();
            if (messages.length > this.messages.length) {
              this.messages = messages;
              this.$nextTick(() => {
                const messages = this.$refs.messages;
                messages.scrollTop = messages.scrollHeight;
              });
            }
            setTimeout(fetchMessages, 1000);
          }
        };
        fetchMessages();
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-container {
  width: 100%;
  max-width: 1000px;
  border-radius: 5px;
  margin: 80px auto;
  padding-bottom: 2px;

  .messages {
    width: 100%;
    max-height: 50vh;
    overflow-y: scroll;

    .message {
      padding: 10px;
      margin: 10px;
      border-radius: 5px;
      color: black;
      width: calc(100% - 20px);
      box-sizing: border-box;
      display: flex;

      p {
        padding: 10px 20px;
        border-radius: 5px;
        max-width: 80%;

        span {
          font-size: 0.8em;
          color: #666;
          display: inline-block;
          margin-top: 8px;
        }
      }

      &.system {
        justify-content: flex-start;

        p {
          border: solid 1px #939da1;
          text-align: left;
        }
      }

      &.user {
        justify-content: flex-end;

        p {
          border: solid 1px #2596be;
          text-align: left;

          span {
            text-align: left;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2562be;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #2562be;
      }
    }
  }

  .message-bar {
    display: flex;

    textarea, input {
      flex: 1;
      padding: 10px;
      background: none;
      border: none;
      border-top: 1px solid #ccc;
      border-radius: 0 0 0 5px;
      font-size: 16px;
      font-family: inherit;

      &:focus {
        outline: none;
      }
    }

    button {
      padding: 13px;
      border: none;
      border-top: 1px solid #ccc;
      background: none;
      border-radius: 0 0 5px 0;
      cursor: pointer;
    }
  }
}

@media (max-width: 600px) {
  .chat-container {
    height: 80vh;
    border-radius: 0;
    border-right: none;
    border-left: none;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 80px;
  }
}
</style>