<template>
  <div class="landing-page">
    <header class="header">
      <div id="header-bg"></div>

      <div id="choose-integration">
        <p>Choose an integration:</p>
        <div>
          <router-link to="/cliniko" class="integration">Cliniko</router-link>
          <router-link to="/practicehub" class="integration">PracticeHub</router-link>
          <router-link to="/nookal" class="integration">Nookal</router-link>
          <router-link to="/coreplus" class="integration">CorePlus</router-link>
        </div>
      </div>

      <div id="desktop-flex">
        <div>
          <h1 class="title">The <br v-if="mobile" /><span class="gradient-text">AI Receptionist</span><br />that
            automates<br />
            <span :class="channel_name == 1 ? 'text-visible' : 'text-hidden'"><span
                class="gradient-text">Phone</span>.</span>
            <span :class="channel_name == 2 ? 'text-visible' : 'text-hidden'"><span
                class="gradient-text">WhatsApp</span>.</span>
            <span :class="channel_name == 3 ? 'text-visible' : 'text-hidden'"><span
                class="gradient-text">Email</span>.</span>
            <span :class="channel_name == 4 ? 'text-visible' : 'text-hidden'"><span
                class="gradient-text">SMS</span>.</span>
          </h1>
          <p id="subheading">The AI Assistant that engages your clinic's customers 24/7, handling appointment requests,
            answering
            questions & more - all while you're off the clock.
            <br /><br />
          <form>
            <input type="email" v-model="email" placeholder="Enter email address" required />
            <button @click.prevent="signUp">SIGN UP FREE</button>
          </form>
          </p>
        </div>

        <img v-if="!mobile" src="@/assets/landing.png" alt="">
      </div>
    </header>

    <img v-if="mobile" src="@/assets/landing.png"
      style="width: 100%; max-width: 600px; display: block; margin: auto; margin-bottom: 80px;" alt="">

    <section style="margin-bottom: 0px">
      <p id="industry-leading" class="hidden">Built with industry-leading tech</p>
      <Carousel :mobile="mobile" :integrations="['Cliniko', 'PracticeHub']" />
    </section>

    <section id="how-it-works">
      <p v-if="mobile" class="hidden">How It Works</p>
      <h1 class="hidden how-it-works" style="margin-top: 10px;">Never miss a booking again</h1>
      <p class="hidden delay-1 how-it-works">
        No more bookings lost during holidays or late-night enquiries. Simply link your booking system & communication
        channels to let our AI take care of it all.
      </p>

      <div id="tiles">
        <div class="tile" id="integrations">
          <img src="@/assets/calendar.svg" alt="AI Learning" />
          <div class="tile-text">
            <h2>Connect your booking system</h2>
            <p>Choose from our ever-growing list of integrated booking systems.</p>
          </div>
        </div>

        <div class="tile">
          <img src="@/assets/book-alt.svg" alt="AI Learning" />
          <div class="tile-text">
            <h2>Add clinic knowledge</h2>
            <p>Add you website and BookedSolid can answer all your customers' queries.</p>
          </div>
        </div>

        <div class="tile">
          <img src="@/assets/globe.svg" alt="AI Learning" />
          <div class="tile-text">
            <h2>Engage in any language</h2>
            <p>Our system supports over 80 languages and detects which is being used.</p>
          </div>
        </div>

        <div class="tile">
          <img src="@/assets/wisdom.svg" alt="AI Learning" />
          <div class="tile-text">
            <h2>Hands-free learning</h2>
            <p>Our AI continuously learns information about your business' services to deliver impeccable customer
              service.</p>
          </div>
        </div>

        <div class="tile">
          <img src="@/assets/pending.svg" alt="AI Learning" />
          <div class="tile-text">
            <h2>24/7 responses</h2>
            <p>You can choose if the AI runs 24/7, or just when you're out of the office, so you never miss a booking
              again.</p>
          </div>
        </div>

        <div class="tile">
          <img src="@/assets/coins.svg" alt="AI Learning" />
          <div class="tile-text">
            <h2>Accept payments</h2>
            <p>Take payments upfront to protect your clinic's revenue from no-shows.</p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <h1 class="hidden how-it-works" style="margin-top: 10px;">Built for efficiency</h1>
      <p class="hidden delay-1 how-it-works">
        Our AI is built to handle all your customer enquiries, so you can
        focus on what you do best.
      </p>

      <div id="stats">
        <div class="stat">
          <h2>5x</h2>
          <p>More efficient than a human employee for daily operational tasks.</p>
        </div>

        <div class="stat">
          <h2>20-30%</h2>
          <p>Of practitioners' time is spent on non-clinical, administrative work.</p>
        </div>

        <div class="stat">
          <h2>40x</h2>
          <p>More cost-effective than hiring additional staff for administrative support.</p>
        </div>

        <div class="stat">
          <h2>70%</h2>
          <p>Of patients prefer self-service scheduling options over traditional calls.</p>
        </div>
      </div>
    </section>


    <section id="demo">
      <h1 class="hidden how-it-works" style="margin-top: 10px;">Personal & accurate</h1>
      <audio id="demo-audio" style="margin: auto; display: block; margin-top: 50px;" src="/bookedsolid-demo-2.mp3"
        controls></audio>
      <div id="bg"></div>
    </section>

    <section id="pricing">
      <h1 class="hidden how-it-works">Simple pricing</h1>
      <p class="hidden delay-1 how-it-works">All plans include a 14-day free trial, unlimited support & set-up
        guidance from our team.</p>

      <div id="currency-selector">
        <button :class="currency == 'gbp' && 'selected'" @click="currency = 'gbp'">£GBP</button>
        <button :class="currency == 'usd' && 'selected'" @click="currency = 'usd'">$USD</button>
        <button :class="currency == 'aud' && 'selected'" @click="currency = 'aud'">$AUD</button>
        <button :class="currency == 'eur' && 'selected'" @click="currency = 'eur'">€EUR</button>
      </div>

      <div id="pricing-tiles">
        <div class="pricing-tile">
          <h2>1 Practitioner</h2>
          <span class="divider"></span>
          <p class="price" v-if="currency == 'gbp'">£39/mo</p>
          <p class="price" v-else-if="currency == 'usd'">$49/mo</p>
          <p class="price" v-else-if="currency == 'aud'">$69/mo</p>
          <p class="price" v-else-if="currency == 'eur'">€45/mo</p>
          <p>Incl. Phone, WhatsApp, Email & SMS</p>
          <span class="divider"></span>
          <button @click="openSite('https://dashboard.bookedsolid.co.uk/login?show=register')">Get Started <i
              class="fa-solid fa-arrow-up-right-from-square"></i></button>
        </div>

        <div class="pricing-tile">
          <h2>2-4 Practitioners</h2>
          <span class="divider"></span>
          <p class="price" v-if="currency == 'gbp'">£79/mo</p>
          <p class="price" v-else-if="currency == 'usd'">$99/mo</p>
          <p class="price" v-else-if="currency == 'aud'">$139/mo</p>
          <p class="price" v-else-if="currency == 'eur'">€89/mo</p>
          <p>Incl. Phone, WhatsApp, Email & SMS</p>
          <span class="divider"></span>
          <button @click="openSite('https://dashboard.bookedsolid.co.uk/login?show=register')">Get Started <i
              class="fa-solid fa-arrow-up-right-from-square"></i></button>
        </div>

        <div class="pricing-tile">
          <h2>5+ Practitioners</h2>
          <span class="divider"></span>
          <p class="price">Get in touch</p>
          <p>Includes options for bespoke functionality</p>
          <span class="divider"></span>
          <button @click="goToContact">Contact Sales</button>
        </div>
      </div>
    </section>

    <section id="review">
      <p style="text-align: center;"><b>"We are blown away with BookedSolid. It pretty much handles all of our e-mails,
          phone calls and messages that people send to us via WhatsApp. We hardly need to answer our phone or reply to
          e-mails anymore and this has saved us hours of time. The dashboard is also incredibly user friendly and allows
          you to make real-time edits to scripts and settings. BookedSolid has made our practice run much more
          efficiently and also helped us cut down on admin/personnel costs!!"</b><br /> - Lissy, Clinic Owner</p>

      <p style="text-align: center;"><b>"As a business owner, there's nothing better than waking up to find 5-10 extra bookings made through
          BookedSolid, without having to do anything!"</b><br /> - Simon Hayes</p>
    </section>

    <section id="faqs">
      <h1 class="hidden how-it-works">Frequently asked questions</h1>
      <div id="faqs-container">
        <div class="faq">
          <a><i class="fas fa-question"></i></a>
          <span>
            <h2>How does BookedSolid work?</h2>
            <p>BookedSolid is powered by AI to give human-like responses to your customers. The AI is trained on your
              clinic's schedule and general information to be able to answer customer queries and book appointments on
              their behalf.</p>
          </span>
        </div>
        <div class="faq">
          <a><i class="fas fa-lock"></i></a>
          <span>
            <h2>Are appointments secure?</h2>
            <p>Yes, our system only allows a customer to book an appointment if the phone number or email address they
              have contacted with matches what's on their account. First-time customers will have an account
              automatically generated on their behalf.</p>
          </span>
        </div>
        <div class="faq">
          <a><i class="fas fa-link"></i></a>
          <span>
            <h2>What systems do you integrate with?</h2>
            <p>We currently integrate with Cliniko, PracticeHub, Nookal, SimplyBook.me and Square. We're always looking
              to add more integrations, so if you use a different system, let us know!</p>
          </span>
        </div>
        <div class="faq">
          <a><i class="fas fa-pen"></i></a>
          <span>
            <h2>Can I customise the AI?</h2>
            <p>Yes, you can customise the AI to respond to certain queries in a specific way. You can also set up
              trigger-based messages to start conversations with customers.</p>
          </span>
        </div>
        <div class="faq">
          <a><i class="fa-solid fa-stethoscope"></i></a>
          <span>
            <h2>Do you only serve businesses in allied health?</h2>
            <p>While our AI works particularly well for allied health clinics, it can be customised to work for any
              business that takes appointments. Contact us to find out how it can work for your business.</p>
          </span>
        </div>
        <div class="faq">
          <a><i class="fas fa-gears"></i></a>
          <span>
            <h2>How long does setup take?</h2>
            <p>Setup is usually completed within 48 hours and with less than 15 minutes of work. Our team will guide you
              through the process and help you customise the AI to your clinic's needs.</p>
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue';
import DemoChat from '@/components/DemoChat.vue';
export default {
  name: 'LandingPage',
  components: {
    Carousel,
    DemoChat
  },
  data() {
    return {
      name: '',
      email: '',
      company: '',
      message: '',
      formLoaded: false,
      clicked: false,
      lastScroll: 0,
      channel_name: 1,
      mobile: window.innerWidth < 925,
      currency: 'gbp',
    };
  },
  methods: {
    async contact() {
      if (this.name == "" || this.email == "" || this.issue == "" || this.more == "") {
        alert("Please fill out all fields.");
        return;
      } else {
        fetch(
          `https://docs.google.com/forms/d/e/1FAIpQLSeXtGPjBlW161jYbac9fpS__GT4Nv9B3Vq4n-6iAkRr_HOGOQ/formResponse?usp=pp_url&entry.1039398541=${this.name}&entry.1981332653=${this.email}&entry.859721265=${this.company}&entry.645716167=${this.message}`
        ).catch(() => { });
        this.name = "";
        this.email = "";
        this.company = "";
        this.message = "";
        alert("Thank you for contacting us. We will get back to you shortly.");
      }
    },
    async signUp() {
      if (this.email == "") {
        alert("Please enter your email address.");
        return;
      } else {
        fetch(
          `https://docs.google.com/forms/d/e/1FAIpQLSeXtGPjBlW161jYbac9fpS__GT4Nv9B3Vq4n-6iAkRr_HOGOQ/formResponse?usp=pp_url&entry.1039398541=${this.name}&entry.1981332653=${this.email}&entry.859721265=${this.company}&entry.645716167=${this.message}`
        ).catch(() => { });
        setTimeout(() => {
          window.location.href = `https://dashboard.bookedsolid.co.uk/login?show=register&email=${this.email}`;
        }, 500);
      }
    },
    async openSite(url) {
      window.open(url, '_blank');
    },
    handleClick() {
      this.clicked = true;
    },
    handleScroll() {
      if (document.getElementById('carousel').scrollLeft < this.lastScroll) this.clicked = true;
    },
    handleInterval() {
      if (this.channel_name == 4) this.channel_name = 1;
      else this.channel_name++;
    },
    updateMobile() {
      this.mobile = window.innerWidth < 925;
    },
    goToContact() {
      this.$router.push('/contact');
    },
  },
  mounted() {
    setTimeout(() => {
      this.formLoaded = true;
    }, 500);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    document.querySelectorAll('.hidden').forEach(el => {
      observer.observe(el);
    });

    setInterval(this.handleInterval, 1500);
    window.addEventListener('resize', this.updateMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMobile);
  },
};
</script>

<style lang="scss" scoped>
:root {
  /* Height of the cut*/
  --cut: 100px;
}

.hidden {
  opacity: 0;
  transition: all 1s;
}

.slide-in {
  transform: translateX(-200px);
}

.slide-in-right {
  transform: translateX(200px);
}

@media (prefers-reduced-motion) {
  .hidden {
    opacity: 1;
  }
}

.show {
  opacity: 1;
  transform: translateX(0);
}

.delay-1 {
  transition-delay: 0.2s !important;
}

.delay-2 {
  transition-delay: 0.6s !important;
}

p,
li {
  font-size: 19px;
  color: #666;
}

.landing-page {
  text-align: center;
  padding: 40px;
}

.gradient-text {
  background: linear-gradient(to right, #2596be, #2562be);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.text-visible {
  position: absolute;
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}

.text-hidden {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.header {
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: left;

  #header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    z-index: -1;
    background: radial-gradient(ellipse at 60% 45%, #2596be80, white 60%);
  }

  @media screen and (min-width: 925px) {
    #desktop-flex {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        width: 35%;
        text-align: left;

        p {
          width: 100%;
        }
      }

      img {
        margin-left: 5%;
        width: 35%;
      }
    }
  }

  #choose-integration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;

    div {
      display: flex;
    }

    .integration {
      font-size: 1em;
      padding: 5px 20px;
      border: solid 1px #2562be;
      // background-color: #2562be;
      border-radius: 100px;
      margin-left: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      text-decoration: none;
      color: inherit;


      &:hover {
        background: #2562be;
        color: white;
      }
    }
  }

  h1 {
    font-size: 3.6vw;
    font-weight: bold;
    margin: 20px auto 40px;
    width: 80vw;
    max-width: 800px;
    text-transform: none;
  }

  #subheading {
    font-size: 1.3vw;
    width: 80vw;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 100px;
    color: #2c3e50;
  }

  form {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 20px;

    input {
      padding: 10px;
      height: 50px;
      width: 40%;
      font-size: 16px !important;
      color: inherit;
      border: 1px solid #2562be;
      border-radius: 4px 0 0 4px;
      border-right: none;
      box-sizing: border-box;
      background: none;
      font: inherit;
    }

    button {
      font-size: 16px;
      letter-spacing: 1px;
      padding: 10px 20px;
      height: 50px;
      font-weight: bold;
      text-decoration: none;
      background: linear-gradient(to left, #2562be, #2562be);
      color: #fff;
      border: none;
      border-radius: 0 4px 4px 0;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background: linear-gradient(to top left, #2596be, #2562be);
      }
    }
  }
}

section {
  position: relative;
  width: 80vw;
  max-width: 1100px;
  margin: 150px auto;
  text-align: left;

  #how-it-works {
    margin-bottom: 0;
  }

  .how-it-works {
    text-align: center;
  }

  #industry-leading {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  #tiles {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
  }

  .tile {
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 25px #1e232820;
    padding: 20px;
    border-radius: 6px;
    margin: 20px;
    width: calc(33% - 80px);
    text-align: center;

    img {
      width: 35px;
      display: block;
      margin: auto;
    }

    .tile-text {
      h2 {
        font-size: 1.4em
      }

      p {
        font-size: 1.1em;
        color: #888;
        margin: 20px 0;
      }
    }
  }

  #stats {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;

    .stat {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 6px;
      margin: 20px;
      width: calc(25% - 80px);
      text-align: center;

      h2 {
        font-size: 2.5em;
        margin: 10px;
      }

      p {
        font-size: 1.1em;
        color: #888;
        margin: 20px 0;
      }
    }
  }

  #currency-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    button {
      font-size: 0.9em;
      padding: 10px 20px;
      border: solid 2px #2562be;
      border-right: none;
      background: none;
      color: #2562be;
      cursor: pointer;
      transition: all 0.3s ease;

      &.selected {
        background: #2562be;
        color: white;
      }

      &:hover {
        background: #2562be;
        color: white;
      }

      &:first-child {
        border-radius: 100px 0 0 100px;
      }

      &:last-child {
        border-radius: 0 100px 100px 0;
        border-right: solid 3px #2562be;
      }
    }
  }

  #pricing-tiles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .pricing-tile {
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 35px #1e232820;
    padding: 20px;
    border-radius: 6px;
    margin: 20px;
    margin-bottom: 40px;
    width: calc(33% - 80px);
    background: white;

    h2 {
      font-size: 1.3em;
      margin-bottom: 10px;
      margin-top: 20px;
      letter-spacing: 0.08em;
      text-align: center;
    }

    .divider {
      display: block;
      height: 1px;
      width: 100%;
      background: #ddd;
      margin: 20px 0;
    }

    p {
      margin: 0;
      font-size: 1.1em;
      text-align: center;
    }

    .price {
      font-size: 1.7em;
      margin: 10px 0;
      margin-bottom: 20px;
      text-align: center;
    }

    button {
      font-size: 1.1em;
      padding: 10px 20px;
      border: none;
      background: linear-gradient(to bottom right, #2596be, #2562be);
      color: white;
      border-radius: 4px;
      transition: all 0.3s ease;
      margin-top: 10px;
      margin-bottom: 20px;
      cursor: pointer;

      i {
        font-size: 0.8em;
        // color: #ccc;
        margin-left: 7px;
      }

      &:hover {
        background: linear-gradient(to top left, #2596be, #2562be);
      }
    }
  }

  &#faqs {
    margin-bottom: 40px;
  }

  #faqs-container {
    column-count: 2;
    column-gap: 40px;
    margin-top: 40px;

    .faq {
      display: flex;
      width: 100%;
      margin-bottom: 40px;
      height: 100px;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9em;
        border: solid 1px #e4e4e4;
        border-radius: 5px;
        margin-right: 20px;
        height: 35px;
        width: 35px;
      }

      h2 {
        font-size: 1em;
        margin-top: 0;
      }

      p {
        font-size: 0.9em;
        line-height: 1.2rem;
        color: #888;
        margin-top: 10px;
      }
    }
  }

  .two-columns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    div {
      width: 45%;
    }

    img,
    video {
      width: 45%;
    }

    &.right {
      flex-direction: row-reverse;
    }
  }

  h1 {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: none;
    background: linear-gradient(to right, #2596be, #2562be);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 40px;
    line-height: 1.8rem;
    letter-spacing: 0.6px;

    &.portrait {
      max-width: 500px;
    }
  }

  .cta {
    display: block;
    margin: 50px auto;
    width: 100px;
    text-align: center;
    padding: 20px 30px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.7px;
    text-decoration: none;
    background-color: #2562be;
    border: none;
    border-radius: 8px;
    color: #fff;
    transition: background-color 0.3s ease;
    cursor: pointer;

    a {
      color: white;
      text-decoration: none;

      i {
        margin-left: 10px;
      }
    }

    &:hover {
      background-color: #1e7b9c;
    }
  }
}

#demo {
  #bg {
    background: linear-gradient(to top, white, #2596be35, #2596be45, #2596be35, white);
    width: 1000vw;
    position: absolute;
    height: 55vh;
    max-height: 1500px;
    top: -150px;
    left: -1000px;
    z-index: -1;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #1e7b9c;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  margin: auto;
}

@media (max-width: 925px) {
  .landing-page {
    padding: 40px 20px;
  }

  .header {
    margin-top: 80px;

    .title {
      font-size: 10vw;
    }

    #subheading {
      font-size: 17px;
      margin-top: 70px;
    }

    #header-bg {
      height: 100vh;
      margin-top: -40px;
      background: radial-gradient(ellipse at 60% 50%, #2596be80, white 60%);
    }

    #choose-integration {
      flex-direction: column;
      margin-top: -40px;
      margin-bottom: 30px;

      div {
        flex-wrap: wrap;
        justify-content: center;
      }

      p {
        margin: 10px;
      }

      .integration {
        margin: 5px;
      }
    }

    form {
      flex-direction: column;
      margin-top: 20px;

      input,
      button {
        width: 100%;
        border-radius: 4px;
      }

      input {
        border: solid 1px #2562be;
        margin-bottom: 10px;
      }
    }
  }

  section {
    width: 90vw;
    margin: auto;
    margin-bottom: 120px;

    h1 {
      font-size: 8vw;
    }

    #how-it-works {
      margin-top: 60px;
    }

    .how-it-works {
      text-align: left;
    }

    .tile {
      margin: 0;
      margin-bottom: 40px;
      width: 100%;
    }

    #stats {
      flex-direction: column;

      .stat {
        width: 100%;
        box-sizing: border-box;
        margin: 0;

        p {
          margin: 0;
        }
      }
    }

    .pricing-tile {
      width: 100%;
      margin: 0;
      margin-bottom: 40px;
    }

    .two-columns {
      flex-direction: column;

      &.right {
        flex-direction: column;
      }

      div {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }

    #faqs-container {
      column-count: 1;
      column-gap: 0;

      .faq {
        width: 100%;
        margin-bottom: 20px;
        height: auto;
      }
    }
  }

  #demo {
    #bg {
      height: 40vh;
      top: -100px;
    }
  }
}
</style>
