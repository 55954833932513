import axios from "axios";
// const url = "https://v2.api.bookedsolid.co.uk";
const url = "http://localhost:5002";

/***********  Demo functions ***********/

async function sendDemoMessage(message) {
  const response = await axios.post(`${url}/ai/demo/webhook`, { id: localStorage.demoId || null, message });
  console.log(response);
  if (response.status == 200) return response.data;
  else return false;
}

async function getDemoConversation() {
  const id = localStorage.demoId || null;
  const response = await axios.get(`${url}/ai/demo/conversation?id=${id}`);
  console.log(response);
  if (response.status == 200) return response?.data?.messages;
  else return false;
}

export {
  sendDemoMessage,
  getDemoConversation
};