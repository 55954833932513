<template>
  <HeaderComponent />
  <router-view/>
  <FooterComponent />
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  }
};
</script>


<style lang="scss">
html, body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

.v-toast {
  font-family: 'Poppins', sans-serif;
  font-size: small;
}

#app {
  font-family: "Questrial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;

  button, input {
    font-family: inherit;
  }
}
</style>
